.quote {
    font-family: 'DancingScript';
    font-size: 2.2rem;
}

.quote__section {
    text-align: center;
    padding: 7rem;
}

.quote__einstein {
    margin-top: 1rem;
    font-size: 1.5rem;
    font-style: italic;
}

@media screen and (max-width: 780px) {
    .quote__section {
        padding: 5rem 1rem;
    }
}