.header {
	position: fixed;
	width: 100vw;
	z-index: 40;
	transition: opacity 0.5s ease-in-out;
	border-bottom: solid 1px grey;
	background-color: white;
	align-self: start;
}

.header__content {
	max-width: 90rem;
	margin: 0 auto;
	color: var(--black-color);
	display: flex;
	justify-content: space-between;
	white-space: nowrap;
	transition: background-color 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.header__logo--container {
	color: transparent;
	padding: 0.5rem 1rem;
	max-height: 5rem;
}

.header__logo {
	max-height: 100%;
	max-width: 100%;
}

.header__content h1 {
	background-color: red;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
}

.navbar{
	position: absolute;
	text-align: left;
	top: 100%;
	left: 0;
	width: 100%;
	transform: scale(1, 0);
	transform-origin: top;
	transition: transform 400ms ease-in-out;
	height: 100vh;
	background: white;
}
.navbar.navOpen{
	transform: scale(1, 1);
}
.menu__items{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding-top: var(--s3);
}
.menu__item{
	position: relative;
}
.dropdown{
	display: flex;
	flex-direction: column;
}
.dropdown__menu__item{
	text-align: center;
}
.dropdown__item__link{
	padding: var(--s-1);
	display: block;
}
.dropdown__item__link:hover , .dropdown:hover .item__link{
	text-decoration: underline;
}
.item__link{
	color: var(--black-color);
	text-decoration: none;
	padding: 2rem 1.8rem;
	transition: color 0.2s;
	white-space: nowrap;
	display: block;
	text-align: center;
}
.item__link--account{
	position: relative;
}
.item__link--account img{
	display: none;
}
.item__link:hover{
	background-color: rgb(0, 0, 0, 0.08);
}

.topnav .icon{
	display: none;
}
.toggle-button{
	padding: 1.4rem 1.8rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 0.6rem;
	transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
	cursor: pointer;
}
.toggle-button-bar{
	display: block;
	background: var(--black-color);
	height: 5px;
	width: 2.5em;
	border-radius: 2px;
	position: relative;
}
.nav-toggle{
	position: absolute !important;
	top: -100% !important;
}

@media screen and (min-width: 55rem){
	.header{
		position: relative;
	}
	.navbar{
		position: relative;
		height: auto;
		width: auto;
		transform: scale(1, 1);
		display: flex;
		justify-self: flex-end;
		background: none;
		left: unset;
		right: 0;
    	transition: right 0.3s ease;
	}
	.menu__items{
		flex-direction: row;
		text-align: center;
		padding-top: 0vh;
	}
	.toggle-button{
		display: none;
		visibility: hidden;
		opacity: 0;
	}
	.menu__item:not(:last-child):before{
		content: '|';
		color: var(--light-color);
		position: absolute;
		right: 0;
		top: 50%;
		transform: translate(50%, -50%);
	}
}