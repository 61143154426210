/*   INDEX CSS: Algemene styling voor hele site (vooral variabelen en declaraties van fonts)   */

@font-face {
  font-family: DancingScript;
  font-weight: normal;
  src: url('assets/fonts/DancingScript-SemiBold.ttf');
}

@font-face {
  font-family: GothicA1;
  font-weight: normal;
  src: url('assets/fonts/GothicA1-Medium.ttf');
}

@font-face {
  font-family: Oswald;
  font-weight: normal;
  src: url('assets/fonts/Oswald-Medium.ttf');
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  box-sizing: inherit;
  scroll-behavior: smooth;
  min-height: 100%;
}

:root {
  --s-99: .1rem;
  --s-2: .2rem;
  --s-1: .5rem;
  --s0: 1rem;
  --s1: 1.5rem;
  --s2: 2rem;
  --s3: 3rem;

  --site-min-width: 70rem;
  --background-color: #ececec;
  --black-color: #181818;
  --color-light-blue: rgb(2, 129, 172);
  --color-error: rgb(163, 0, 0);
  --title-font: 'Oswald', Verdana, Geneva, Tahoma, sans-serif;
}

#root {
  line-height: 1.2;
  font-size: 1.2rem;
  font-family: 'GothicA1', sans-serif;
  background-color: var(--background-color);
  color: var(--black-color);
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  overflow: hidden;
}

.container{
  align-self: start;
}

/*   GENERAL STYLING   */
a {
  text-decoration: none;
  color: inherit;
  font-family: inherit;
}

strong {
  font-weight: bold;
}

.elevation{
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.2);
}

.elevation-strong{
 box-shadow: 0rem 0rem 1rem rgba(0, 0, 0, 0.5)
}

.hidden {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.preload {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}