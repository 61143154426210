.tab--radio {
    display: none;
}

.tabs {
    width: 100%;
    display: flex;
    align-items: center;
}

.tab {
    width: 100%;
    cursor: pointer;
    padding: var(--s0);
    transition: color 0.1s ease-in-out, font-weight 0.1s ease-in-out, font-size 0.1s ease-in-out;
    color: gray;
    text-transform: uppercase;
    text-align: center;
    font-weight: normal;
    min-height: 100%;
}

.tab:hover {
    background-color: rgb(231, 231, 231);
    color: black;
}

.tabs--barBelow {
    height: var(--s-2);
    background-color: black;
    transition: transform 0.2s ease-in-out;
    display: none;
}

.tab-selected {
    color: black;
    font-weight: bold;
}