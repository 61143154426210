.footer {
	background-color: var(--black-color);
	padding: 1rem;
	color: white;
	align-self: end;
}

.footer__wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	gap: var(--s2);
}

.footer__wrapper--socials{
	display: flex;
}

.footer__logo--container {
	color: transparent;
	padding: 0.5rem 1rem;
	max-width: 10rem;
}

.footer__logo--container  img{
	width: 100%;
}

.socials__container h2 {
	font-weight: bold;
	margin-bottom: var(--s0);
}

.socials {
	display: flex;
	gap: var(--s1);
	justify-content: center;
}

.social__item {
	text-align: center;
	text-decoration: none;
	color: white;
}

.social__icon {
	height: 1.8rem;
	margin-bottom: var(--s-1);
}

.social__item:hover h3 {
	text-decoration: underline;
}

.details__container {
	text-align: right;
}

.details__container img {
	width: 7rem;
}

.copyright__text {
	font-style: italic;
	font-size: 0.8rem;
	margin-top: var(--s1);
	text-align: center;
}