.image-slider__section {
    background-color: black;
    overflow: hidden;
}

.slide-images {
    display: grid;
    width: 100%;
    height: 30rem;
    overflow: hidden;
    place-items: center;
    position: relative;
}

.slide-image {
    opacity: 0;
    grid-row: 1;
    grid-column: 1;
    transition: opacity 2.2s ease-in-out;
    min-height: 100%;
    min-width: 100%;
    max-width: 200%;
    width: 1100px;
}

.imageSlider-active {
    opacity: 100%;
}