.hero__section{
    height: 70vh;
    position: relative;
}

.hero__image{
    background-image: url('../../assets/images/pages/home/heroImage.jpg');
    background-position: 60% 30%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
}

.hero__content{
    color: white;
    text-align: center;
    justify-self: end;
    margin: 2rem;
}

.hero__title{
    font-size: calc(70vw/9);
    font-weight: 700;
    margin-bottom: 0.5rem;
    font-family:  Oswald;
    text-shadow:  0 0 15px var(--black-color);
}

.hero__subtitle{
    font-size: calc(70vw/19);
    font-weight: 400;
    margin-bottom: 1rem;
    font-family: DancingScript;
    text-shadow: 0 0px 10px var(--black-color);
}

@media screen and (max-width: 500px) {
    .hero__title {
        font-size: 5rem;
    }

    .hero__subtitle {
        font-size: 2.1rem;
    }

    .hero__image {
        background-position: right;
    }
}

/* Small Hero Section */
.hero__section-small{
    height: 55vh;
    position: relative;
}

.hero__section-small--img{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 960px) {
    .hero__section-small {
        height: 50vh;
    }
}

.hero__section-small--img.bio{
    background-image: url('../../assets/images/pages/bio/ADW_performing_bar.jpg');
}
.hero__section-small--img.annchante{
    background-image: url('../../assets/images/pages/annchante/annchante.jpg');
}
.hero__section-small--img.donna{
    background-position: 60% center;
    background-image: url('../../assets/images/pages/donna/donna-hero.jpg');
}