.concepten__section{
    padding-top: 1rem;
}

.concepten__section--wrapper {
    margin: 2rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    gap: 2rem;
}

.concept__container {
    width: 25rem;
    height: 15rem;
    background-color: black;
}

.concept__container:hover {
    cursor: pointer;
}

.concept__container:hover .concept__container--cover {
    background-color: rgb(0, 0, 0, 0.1);
}

.concept__container:hover .concept-title {
    transform: scale(1.2);
    background-color: rgb(0, 0, 0, 0.8);
}

.concept__container--cover {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: rgb(0, 0, 0, 0.5);
    transition: background-color 0.5s ease-in-out;
    color: white;
}

.concept__container--cover .concept-title {
    font-family: 'Oswald';
    font-size: 2rem;
    text-align: center;
    padding: 0.5rem;
    background-color: rgb(0, 0, 0, 0.5);
    box-shadow: 0 0 10px var(--black-color);
    transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out, background-color 0.5s ease-in-out;
}

.concept-concert {
    background-image: url(../../assets/images/pages/home/concepten/concert2.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.concept-feest {
    background-image: url(../../assets/images/pages/home/concepten/feest1.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.concept-uitvaart {
    background-image: url(../../assets/images/pages/home/concepten/ceremonie2.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.concept-huwelijk {
    background-image: url(../../assets/images/pages/home/concepten/ceremonie1.jpg);
    background-position: 80% 60%;
    background-size: cover;
    background-repeat: no-repeat;
}

@media screen and (max-width: 880px) {
    .concept__container {
        width: 18rem;
        height: 10rem;
    }

    .concept__container--cover .concept-title {
        font-size: 1.6rem;
        padding: 0.3rem;
    }
}

@media screen and (max-width: 670px) {
    .concepten__section--wrapper {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        width: 80%;
    }

    .concept__container {
        width: 100%;
        height: 10rem;
    }
}