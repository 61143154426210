.layered-grid{
  display: grid;
  place-items: center;
}

.layered-grid * {
  grid-column: 1;
  grid-row: 1;
}

.page{
  margin-top: 5rem;
}

@media screen and (min-width: 55rem) {
  .page {
    margin-top: 0;
  }
}

.section-general {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 1rem;
  max-width: var(--site-min-width);
  margin: 0 auto;
}

.section__title {
  font-family: var(--title-font);
  text-transform: uppercase;
  font-size: 2.5rem;
  text-align: center;
}

.section__title--border {
  height: 10px;
  width: 4rem;
  background-color: var(--black-color);
  margin-top: var(--s0);
}

.button-general{
  background-color: var(--black-color);
  padding: 1rem;
  font-style: inherit;
  font-family: inherit;
  font-size: inherit;
  color: var(--background-color);
  border-radius: 2rem;
  transition: transform .2s ease-in-out;
}

.button-general:hover{
  transform: scale(1.1);
}

.textLink {
  color: rgb(66, 154, 184);
  text-decoration: underline;
  white-space: nowrap;
  cursor: pointer;
}

.textLink:hover {
  filter: brightness(140%);
}